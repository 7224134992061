<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="dashboard-user">

    <div class="knowledge-base-jumbotron">
      <div :style="{'background-image': 'url(https://images.unsplash.com/photo-1489183988443-b37b7e119ba6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1652&q=80)'}" style="background-position: center!important;"
           class="knowledge-base-jumbotron-content lg:p-24 md:p-16 sm:p-10 p-6 rounded-lg bg-cover mb-base">
        <h1 class="mb-1 text-white" style="font-size: 3.9rem">Welcome to Rapid Seminars</h1>
        <h3 class="text-white font-light">Begin by selecting a course to practice</h3>
        <!---  <vs-input icon-no-border placeholder="Search for Course" v-model="course_selector_query" icon-pack="feather" icon="icon-search" size="large" class="w-full mt-6 transparent" /> //!TODO FIX SEARCH --->
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
        <statistics-card-line
          :statistic="`${(avg_progress * 100).toFixed(0)}%`"
          class="mb-base"
          hideChart
          icon="ActivityIcon"
          icon-right
          statisticTitle="Average Progress"/>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
        <statistics-card-line
          :statistic="courses_available"
          class="mb-base"
          hideChart
          icon="BookOpenIcon"
          icon-right
          statisticTitle="Subscribed Courses"/>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
        <statistics-card-line
          :statistic="courses_in_progress"
          class="mb-base"
          hideChart
          icon="LoaderIcon"
          icon-right
          statisticTitle="In-Progress Courses"/>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
        <statistics-card-line
          :statistic="courses_completed"
          class="mb-base"
          hideChart
          icon="CheckSquareIcon"
          icon-right
          statisticTitle="Completed Courses"/>
      </div>

    </div>

    <div>
      <slide-x-left-transition group class="vx-row match-height" tag="div">
        <div v-for="item in subscribedCourses" :key="item.id" class="vx-col card-width-select-course mb-base">
          <course-selector-card :item="item"/>
        </div>
      </slide-x-left-transition>
    </div>
    <h1 v-if="coursesLoaded && subscribedCourses.length === 0">You currently have no courses on your account,<br>You may
      purchase access to a course by visiting Rapid Portal <a href="https://portal.rapid.education">Here</a></h1>

  </div>

  <!---
<div class="vx-col w-full lg:w-1/3 mb-base">
<vx-card slot="no-body" class="text-center bg-success-gradient greet-user">
  <feather-icon class="p-6 mb-8 bg-success inline-flex rounded-full text-white shadow" icon="FlagIcon"
                svgClasses="h-8 w-8"/>
  <h1 class="mb-6 text-white">
    Welcome to Seminars
  </h1>
  <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white">
    We're currently in release v1.0.0
    If you have an issues, please <a class="text-white" href="mailto:support+seminars@rapid.education"
                                     style="text-decoration: underline"
                                     target="_blank">email our support team.</a>
  </p>
</vx-card>
</div>

<div v-if="avg_progress >= .9" class="vx-col w-full lg:w-1/3 mb-base">
<vx-card slot="no-body" class="text-center bg-ishan-flag greet-user">
  <feather-icon class="p-6 mb-8 bg-ishan-flag-reverse inline-flex rounded-full text-white shadow"
                icon="AwardIcon"
                svgClasses="h-8 w-8"/>
  <h1 class="mb-6 text-white">Congratulations {{ activeUserInfo.first_name }},</h1>
  <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white">
    You have a {{ (avg_progress * 100).toFixed(0) }}% completion rate!
    <span v-if="avg_progress < 1">
      Try getting to 100%!
    </span>
    <span v-else>
      What course should you try completing next? 🤔
    </span>
  </p>
</vx-card>
</div>

<div v-else class="vx-col w-full lg:w-1/3 mb-base">
<vx-card slot="no-body" class="text-center bg-danger-gradient greet-user">
  <feather-icon class="p-6 mb-8 bg-danger-gradient inline-flex rounded-full text-white shadow"
                icon="AlertOctagonIcon"
                svgClasses="h-8 w-8"/>
  <h1 class="mb-6 text-white">C'mon {{ activeUserInfo.first_name }},</h1>
  <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white">
    You have a {{ (avg_progress * 100).toFixed(0) }}% completion rate.
    Let's see you reach 100%!
  </p>
</vx-card>
</div>

<div v-if="courses.length > 0" class="vx-col w-full lg:w-1/3 mb-base">
<vx-card v-if="least_successful_course.progress < 1" slot="no-body" class="text-center bg-light-gradient"
         title="Least Complete Course">

  <vs-row>
    <vs-col class="mb-3" vs-w="12">
      <img
        :alt="`${this.least_successful_course.name} Thumbnail`"
        :src="`https://app-cdn.rapidseminars.com/thumbnails/${least_successful_course.thumbnail_id}.jpeg`"
        class="rounded w-full"/>
    </vs-col>

    <vs-col vs-w="12">
      <h4 class="text-center">{{ least_successful_course.name }}</h4>

      <p class="text-dark">
        {{ (least_successful_course.progress * 100).toFixed() }}% complete
      </p>

      <vs-button class="mt-3 w-full" @click="continueCourse(`${least_successful_course.id}`)">
        Continue Course
      </vs-button>

    </vs-col>
  </vs-row>

</vx-card>

<vx-card v-else slot="no-body" class="text-center bg-light-gradient" title="All Courses Complete">

  <vs-row>

    <vs-col vs-w="12">
      <h5 class="text-center">
        No courses left to resume. You have completed all of your courses!
      </h5>

      <vs-button class="mt-5 w-full" to="/courses">
        View Subscribed Courses
      </vs-button>

    </vs-col>
  </vs-row>

</vx-card>
</div>
<div v-else class="vx-col w-full lg:w-1/3 mb-base">
<vx-card slot="no-body" class="text-center bg-light-gradient">

  <vs-row>

    <vs-col vs-w="12">
      <h4 class="text-center">
        You aren't subscribed to any courses.
      </h4>

      <p class="text-dark">
        Visit Rapid Portal to Purchase a Course
      </p>

      <vs-button class="mt-3 w-full" to="/portal/shop">
        Visit Store
      </vs-button>

    </vs-col>
  </vs-row>

</vx-card>
</div>
--->
</template>


<style>

.bg-ishan-flag {
  background: linear-gradient(118deg, #0cebeb, #20e3b2, #29ffc6) !important;
}

.bg-ishan-flag-reverse {
  background: linear-gradient(118deg, #29ffc6, #20e3b2, #0cebeb) !important;
}

</style>

<script>

import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';
import {swiper, swiperSlide} from 'vue-awesome-swiper';
import CourseSelectorCard from "./pages/components/CourseSelectorCard";

export default {
  components: {
    StatisticsCardLine,
    swiper,
    swiperSlide,
    CourseSelectorCard
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    continueCourse(courseId) {
      this.$router.push(`/courses/${courseId}?opt=continue`);
    },
    initiateLeastSuccessfulCourse() {

      if (!this.least_successful_course_id) {
        return;
      }
      let lsc = this.least_successful_course_id;

      this.least_successful_course = this.courses.filter(course => {
        return course.id === lsc;
      })[0];

      if (this.least_successful_course) {
        this.least_successful_course.progress = this.progresses.filter(progress => {
          return progress.course_id === lsc;
        })[0].course_progress;
      }

    },
    loadProgress() {

      this.$http.get(`courses/progress?user_id=${this.activeUserInfo.id}`)
        .then(response => {

          if (response.data) {

            const payload = response.data.data;
            this.progresses = payload;

            this.courses_in_progress = payload.filter(progress => {
              return progress.course_progress < 1;
            }).length;

            this.courses_completed = payload.filter(progress => {
              return progress.course_progress >= 1;
            }).length;

            this.avg_progress = payload.reduce((sum, progress) => {
              return sum + progress.course_progress;
            }, 0) / payload.length;

            if (isNaN(this.avg_progress)) {
              this.avg_progress = 0;
            }

            this.least_successful_courses = payload
              .filter(progress => {
                return this.subscriptions.filter(subscription => {
                  return subscription.course_ids.includes(progress.course_id);
                }).length > 0;
              })
              .sort((a, b) => {
                return a.course_progress - b.course_progress;
              })

            if (this.least_successful_courses) {
              this.least_successful_course = this.least_successful_course[0];
              this.least_successful_course_id = this.least_successful_course.course_id;
            }

            this.initiateLeastSuccessfulCourse();

          }

        });

    },
    loadSubscriptions() {

      this.$http.get(`billing/@me/subscriptions?amount=50`)
        .then(response => {

          if (response.data) {
            const payload = response.data.data;
            this.subscriptions = payload;

            let courseIds = [];

            payload.forEach(subscription => {
              for (let id of subscription.course_ids) {
                if (!courseIds.includes(id)) {
                  courseIds.push(id);
                }
              }
            });


            courseIds.forEach(courseId => {

              this.$http.get(`courses/${courseId}`)
                .then(response => {

                  if (response.data) {
                    const payload = response.data.data;

                    if (payload.status === 'active') {
                      this.subscribedCourses.push(payload);
                    }

                  }

                });

            });

            this.coursesLoaded = true

          /* courseIds.forEach(courseId => {

              this.$http.get(`courses/${courseId}`)
                .then(response => {

                  if (response.data) {

                    const payload = response.data.data;
                    this.$set(this.courses, this.courses.length, payload);

                    this.initiateLeastSuccessfulCourse();
                  }

                });

            }); */

            this.loadProgress();
          }

        });

      this.$http.get(`billing/@me/subscriptions/counts`)
        .then(response => {

          if (response.data) {
            const payload = response.data.data;

            this.courses_available = payload.total;

          }

        });

    },
  },
  data() {
    return {
      avg_progress: 0,
      courses_in_progress: 0,
      courses_completed: 0,
      courses_available: 0,

      least_successful_course: {},
      least_successful_course_id: undefined,

      progresses: [],
      subscriptions: [],
      courses: [],
      courses_test: [],
      subscribedCourses: [],

      coursesLoaded: false

    };
  },
  mounted() {
    this.loadSubscriptions();
  },
};
</script>
<style lang="scss">


.card-width-select-course {
  width: 20%;
}

@media (max-width: 2900px) {
  .card-width-select-course {
    width: 25%;
  }
}

@media (max-width: 2100px) {
  .card-width-select-course {
    width: 33.333333%;
  }
}

@media (max-width: 1600px) {
  .card-width-select-course {
    width: 50%;
  }
}
@media (max-width: 750px) {
  .card-width-select-course {
    width: 100%;
  }
}


</style>
