<!-- =========================================================================================
    File Name: ItemListView.vue
    Description: Item Component - List VIew

========================================================================================== -->

<template>
    <vx-card class="course-selector-card overflow-hidden" :class="item.selected ? 'border border-solid border-success' : ''" v-on="$listeners" >
      <div slot="no-body" style="cursor: pointer;">
        <img
          :src="`https://app-cdn.rapidseminars.com/thumbnails/${item.thumbnail_id}.jpeg`"
          alt="content-img" class="responsive card-img-top" draggable="false"
          style="width: 100%; height: 270px; object-fit: cover;">
      </div>
      <div class="my-2">
        <h1 class="mb-2 font-bold break-words">{{ item.name }}</h1>
      </div>

      <div class="vx-row">
     <div class="vx-col w-full" style="bottom: 0">
       <vs-button class="mt-4 mr-2 shadow-lg font-semibold w-full"
                  icon="icon-play" icon-pack="feather" :color="'primary'"
                  type="gradient" @click="$router.push(`/courses/${item.id}`)">Practice Course
       </vs-button>
        </div>
      </div>
    </vx-card>
</template>

<script>
export default{
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      progress: 0
    }
  },
  computed: {
    activeCourseInfo () {
      return this.$store.state.AppActiveCourse
    }
  },
  methods: {
    update_active_course (x) {
      const pl = {
        course_id: x
      }

      this.$http.post('users/@me/active-course', pl)
        .then(response => {

          if (response.status === 200) {
            this.$vs.notify({
              color: 'success',
              title: 'Course Changed',
              text: 'You have successfully changed your course',
              position: 'bottom-right'
            })

            this.$router.push('/simulation/search')
          }
        }).catch(exception => {
          return this.$vs.notify({
            color: 'danger',
            title: 'Failed to update course',
            text: exception.response.data.error.description,
            position: 'top-right'
          })
        })
    }
  },
  created () {
    this.$http.get(`progress/user/specific/@me/course/progress?id=${this.item.id}`)
      .then(response => {
        this.progress = response.data.data.completion_percent
      })
  }
}
</script>

<style lang="scss" scoped>
.course-selector-card {

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0,0,0,.25);

    .grid-view-img{
      opacity: 0.9;
    }
  }
}
</style>
